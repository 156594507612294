var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ozi-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"persistent":"flsActivityListTable"},on:{"click:row":_vm.handleView},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleCreate.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc('label.create'))+" ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleExport.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$tc('label.export'))+" ")],1),_c('v-spacer'),_c('toggable-date-range-picker',{staticClass:"mt-2",attrs:{"label":_vm.$t('fls_activity.completed_on'),"nativeLabel":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-select',{staticClass:"ml-4 mr-2",staticStyle:{"max-width":"200px"},attrs:{"label":_vm.$tc('fls_activity.created_by', 2),"clearable":"","items":(_vm.coordinator_options || []).map(function (coord) { return ({
              text: coord.last_name + ', ' + coord.first_name,
              value: coord.id,
            }); })},model:{value:(_vm.coordinator),callback:function ($$v) {_vm.coordinator=$$v},expression:"coordinator"}})],1)]},proxy:true},{key:"item.created_by",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_by.first_name)+" "+_vm._s(item.created_by.last_name)+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleEdit(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }