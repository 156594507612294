import axios from 'axios';
import APIUtils from './APIUtils';
import { Enumeration } from './EnumerationUtils';

interface User {
  id: number;
  username: string;
  email: string;
  password?: string;
  first_name: string;
  last_name: string;
  privilege: string;
  delivery_enum: Enumeration;
}

const generateDefault = () => {
  return {
    id: undefined,
    username: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    privilege: '',
    request_method_key: undefined,
  };
};

const api = {
  list: async () => {
    const resp = await axios.get<User[]>('user/list.php');
    return resp.data;
  },
  listCoordinators: async () => {
    const resp = await axios.get<User[]>('user/list_coordinators.php');
    return resp.data;
  },
  get: async (id: number) => {
    const resp = await axios.get<User>('user/get.php', {
      params: { user_id: id },
    });
    return resp.data;
  },
  create: async (item: Partial<User>) => {
    const resp = await axios.post<User>('user/create.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  update: async (item: Partial<User>) => {
    const resp = await axios.put<User>('user/update.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  remove: async (id: number) => {
    const resp = await axios.delete<User>('user/remove.php', {
      params: { user_id: id },
    });
    return resp.data;
  },
};

export default { api, generateDefault };
export { User };
