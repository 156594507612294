


























































import Vue from 'vue';
import FlsActivityUtils, { FlsActivity } from '@/utils/FlsActivityUtils';
import UserUtils, { User } from '@/utils/UserUtils';
import { DataTableHeader } from 'vuetify';
import { locales } from '@/i18n';
import ToggableDateRangePicker from '@/components/framework/datepicker/ToggableDateRangePicker.vue';

export default Vue.extend({
  components: { ToggableDateRangePicker },
  computed: {
    headers(): DataTableHeader<FlsActivity>[] {
      return [
        {
          text: this.$i18n.tc('fls_activity.activity_type'),
          value: 'activity_type.name',
        },
        {
          text: this.$i18n.tc('fls_activity.source'),
          value: 'source',
        },
        {
          text: this.$i18n.tc('fls_activity.delivery_mode'),
          value: 'delivery_mode.name',
        },
        {
          text: this.$i18n.tc('fls_activity.received_on'),
          value: 'received_on',
        },
        {
          text: this.$i18n.tc('fls_activity.completed_on'),
          value: 'completed_on',
        },
        {
          text: this.$i18n.tc('fls_activity.created_by'),
          value: 'created_by',
          filter: this.filterName,
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },

    locales() {
      return locales;
    },

    filteredItems(): FlsActivity[] {
      return (this.items || [])
        .filter(
          (item) =>
            this.coordinator == undefined ||
            item.created_by.id == this.coordinator
        )
        .filter((item) => {
          if (this.dateRange.length == 0) return true;
          else if (this.dateRange.length == 1)
            return this.dateRange[0] == item.completed_on;
          else
            return (
              item.completed_on >= this.dateRange[0] &&
              item.completed_on <= this.dateRange[1]
            );
        });
    },
  },

  data: (): {
    items?: FlsActivity[];
    coordinator_options?: User[];
    coordinator?: number;
    dateRange: string[];
  } => ({
    items: undefined,
    coordinator_options: [],
    coordinator: undefined,
    dateRange: [],
  }),

  watch: {
    coordinator() {
      if (this.coordinator != this.$storage.flsActivityListTable.createdBy)
        this.$storage.flsActivityListTable.createdBy = this.coordinator;
    },
  },

  async mounted() {
    try {
      this.coordinator = this.$storage.flsActivityListTable.createdBy;
      this.items = await FlsActivityUtils.api.list();
      this.coordinator_options = await UserUtils.api.listCoordinators();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    filterName(value: any, search: string | null, item: any): boolean {
      if (search === null) return true;
      return (item.created_by.first_name + ' ' + item.created_by.last_name)
        .toLowerCase()
        .includes(search);
    },
    async handleView(item: FlsActivity) {
      await this.$router.push({
        name: 'fls-activity-view',
        params: { id: item.id.toString() },
      });
    },
    async handleEdit(item: FlsActivity) {
      await this.$router.push({
        name: 'fls-activity-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'fls-activity-create',
      });
    },

    async handleExport() {
      const response = await FlsActivityUtils.api.export({
        created_by_id: this.coordinator,
        from_completed_date: this.dateRange[0],
        to_completed_date: this.dateRange[1],
        locale: this.$i18n.locale,
      });

      const url = URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Activities-${new Date().toLocaleDateString()}-${this.$i18n.locale}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    },
  },
});
